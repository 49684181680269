.ce_huh_hero {
  .hero-container {
    position: relative;
    &.full-size {
      height: 100vh;
    }
    .image-overlay {
      position: absolute;
      z-index: 1;
      width: 100vw;
      height: 100%;
      max-width: unset;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
    }

    .image_container {
      height: 100%;
      width: 100vw;
      max-width: unset;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    .no-image {
      .text-container {
        position: relative;
      }
    }
    .text-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;

      display: flex;
      flex-direction: column;
      justify-content: center;

      &.text {
        &-left {
          align-items: flex-start;
        }
        &-right {
          align-items: flex-end;
        }
        &-center {
          align-items: center;
        }
      }
      h1,h2,h3,h4,h5,h6,div {
        width: fit-content;
      }
    }
  }
}