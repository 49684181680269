.ce_huh_media_text {
  &.no-margin-bottom {
    margin-bottom: 0 !important;
  }

  figure {
    margin: 0;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .additional-image {
    figure {
      margin: 0;

      img {
        height: auto;
        width: auto;
      }
    }
  }

  .image-overlay {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    opacity: .25;
  }

  .no-gutters > .col > [class*="col-"],
  .no-gutters > [class*="col-"] > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }

  .video-player {
    video {
      object-fit: cover;
      height: 100%;
      width: 100%;
      max-height: 100%;
      max-width: 100%;
      margin-bottom: -7px;
    }
  }

  .media-text-1,
  .media-text-2,
  .media-text-4 {
    .box-media {
      padding: 0;
    }

    &.media-right {
      .box-media {
        order: 1;
        @include media-breakpoint-up(lg) {
          order: 3;
        }
      }

      .box-content {
        figure {
          height: 100%;
        }
        order: 2;
      }
    }
  }

  &.col-full {
    .media-text-1,
    .media-text-2 {
      @include media-breakpoint-up(lg) {
        .box-media {
          figure,
          .video-player {
            height: 100%;
            width: 50vw !important;
            position: relative;
          }

          .image-overlay {
            width: 50vw !important;
            z-index: 1;
          }
        }

        &.media-right {
          .box-media {
            figure,
            .video-player {
              margin-right: -50vw;
              right: 0;
            }
          }
        }

        &.media-left {
          .box-media {
            figure,
            .video-player {
              margin-left: -50vw;
              left: 100%;
            }

            .image-overlay {
              @include media-breakpoint-up(lg) {
                margin-left: calc((-100vw + 960px) / 2);
              }

              @include media-breakpoint-up(xl) {
                margin-left: calc((-100vw + 1140px) / 2);
              }
            }
          }
        }
      }
    }

    .media-text-4 {
      @include media-breakpoint-up(lg) {
        .box-media {
          figure,
          .video-player {
            height: 100%;
            width: 33.33333333vw !important;
            position: relative;
          }

          .image-overlay {
            width: 33.33333333vw !important;
            z-index: 1;
          }
        }

        &.media-right {
          .box-media {
            figure,
            .video-player {
              margin-right: -33.33333333vw;
              right: 0;
            }
          }
        }

        &.media-left {
          .box-media {
            figure,
            .video-player {
              margin-left: -33.33333333vw;
              left: 100%;
            }
          }
        }
      }
    }
  }

  .media-text-2 {
    @include media-breakpoint-up(lg) {
      margin-top: 1.25rem !important;
      margin-bottom: 1.25rem !important;
      .box-media {
        margin-top: -1.25rem !important;
        margin-bottom: -1.25rem !important;
      }
    }
  }

  .media-text-3 {
    .box-content-wrapper {
      @include media-breakpoint-up(lg) {
        position: absolute;
        height: 90%;
        top: 5%;
        z-index: 2;
        background: white;
      }
    }

    .box-media {
      padding: 0;
    }

    &.media-left {
      .box-content-wrapper {
        @include media-breakpoint-up(lg) {
          right: 0;
        }

      }

      .box-content {
        @include media-breakpoint-up(lg) {
          @include make-container;
          margin-left: 0;
          margin-right: auto;
          max-width: calc(960px / 2);
        }

        @include media-breakpoint-up(xl) {
          @include make-container;
          margin-left: 0;
          margin-right: auto;
          max-width: calc(1140px / 2);
        }

        @include media-breakpoint-down(md) {
          @include make-container;
          max-width: 720px;
        }
      }
    }

    &.media-right {
      .box-content {
        @include media-breakpoint-up(lg) {
          @include make-container;
          margin-left: auto;
          margin-right: 0;
          max-width: calc(960px / 2);
        }

        @include media-breakpoint-up(xl) {
          @include make-container;
          margin-left: auto;
          margin-right: 0;
          max-width: calc(1140px / 2);
        }

        @include media-breakpoint-down(md) {
          @include make-container;
          max-width: 720px;
        }
      }
    }
  }
}
