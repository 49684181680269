.ce_huh_media_grid figure {
  margin: 0;
}
.ce_huh_media_grid .image-overlay {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.25;
}
.ce_huh_media_grid .image-overlay ~ .text-element {
  position: absolute;
  display: block;
  width: 100%;
  top: 0;
  left: 0;
}

.ce_huh_hero .hero-container {
  position: relative;
}
.ce_huh_hero .hero-container.full-size {
  height: 100vh;
}
.ce_huh_hero .hero-container .image-overlay {
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100%;
  max-width: unset;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
.ce_huh_hero .hero-container .image_container {
  height: 100%;
  width: 100vw;
  max-width: unset;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
.ce_huh_hero .hero-container .image_container img {
  height: 100%;
  object-fit: cover;
}
.ce_huh_hero .hero-container .no-image .text-container {
  position: relative;
}
.ce_huh_hero .hero-container .text-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ce_huh_hero .hero-container .text-container.text-left {
  align-items: flex-start;
}
.ce_huh_hero .hero-container .text-container.text-right {
  align-items: flex-end;
}
.ce_huh_hero .hero-container .text-container.text-center {
  align-items: center;
}
.ce_huh_hero .hero-container .text-container h1, .ce_huh_hero .hero-container .text-container h2, .ce_huh_hero .hero-container .text-container h3, .ce_huh_hero .hero-container .text-container h4, .ce_huh_hero .hero-container .text-container h5, .ce_huh_hero .hero-container .text-container h6, .ce_huh_hero .hero-container .text-container div {
  width: fit-content;
}

.ce_huh_media_grid figure {
  margin: 0;
}
.ce_huh_media_grid figure img {
  width: 100%;
}
.ce_huh_media_grid .image-overlay {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.25;
}
.ce_huh_media_grid .image-overlay ~ .text-element {
  position: absolute;
  display: block;
  width: 100%;
  top: 0;
  left: 0;
}
.ce_huh_media_grid .no-gutters > .col > [class*=col-],
.ce_huh_media_grid .no-gutters > [class*=col-] > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.ce_huh_media_text.no-margin-bottom {
  margin-bottom: 0 !important;
}
.ce_huh_media_text figure {
  margin: 0;
}
.ce_huh_media_text figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.ce_huh_media_text .additional-image figure {
  margin: 0;
}
.ce_huh_media_text .additional-image figure img {
  height: auto;
  width: auto;
}
.ce_huh_media_text .image-overlay {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.25;
}
.ce_huh_media_text .no-gutters > .col > [class*=col-],
.ce_huh_media_text .no-gutters > [class*=col-] > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
.ce_huh_media_text .video-player video {
  object-fit: cover;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  margin-bottom: -7px;
}
.ce_huh_media_text .media-text-1 .box-media,
.ce_huh_media_text .media-text-2 .box-media,
.ce_huh_media_text .media-text-4 .box-media {
  padding: 0;
}
.ce_huh_media_text .media-text-1.media-right .box-media,
.ce_huh_media_text .media-text-2.media-right .box-media,
.ce_huh_media_text .media-text-4.media-right .box-media {
  order: 1;
}
@media (min-width: 992px) {
  .ce_huh_media_text .media-text-1.media-right .box-media,
  .ce_huh_media_text .media-text-2.media-right .box-media,
  .ce_huh_media_text .media-text-4.media-right .box-media {
    order: 3;
  }
}
.ce_huh_media_text .media-text-1.media-right .box-content,
.ce_huh_media_text .media-text-2.media-right .box-content,
.ce_huh_media_text .media-text-4.media-right .box-content {
  order: 2;
}
.ce_huh_media_text .media-text-1.media-right .box-content figure,
.ce_huh_media_text .media-text-2.media-right .box-content figure,
.ce_huh_media_text .media-text-4.media-right .box-content figure {
  height: 100%;
}
@media (min-width: 992px) {
  .ce_huh_media_text.col-full .media-text-1 .box-media figure,
  .ce_huh_media_text.col-full .media-text-1 .box-media .video-player,
  .ce_huh_media_text.col-full .media-text-2 .box-media figure,
  .ce_huh_media_text.col-full .media-text-2 .box-media .video-player {
    height: 100%;
    width: 50vw !important;
    position: relative;
  }
  .ce_huh_media_text.col-full .media-text-1 .box-media .image-overlay,
  .ce_huh_media_text.col-full .media-text-2 .box-media .image-overlay {
    width: 50vw !important;
    z-index: 1;
  }
  .ce_huh_media_text.col-full .media-text-1.media-right .box-media figure,
  .ce_huh_media_text.col-full .media-text-1.media-right .box-media .video-player,
  .ce_huh_media_text.col-full .media-text-2.media-right .box-media figure,
  .ce_huh_media_text.col-full .media-text-2.media-right .box-media .video-player {
    margin-right: -50vw;
    right: 0;
  }
  .ce_huh_media_text.col-full .media-text-1.media-left .box-media figure,
  .ce_huh_media_text.col-full .media-text-1.media-left .box-media .video-player,
  .ce_huh_media_text.col-full .media-text-2.media-left .box-media figure,
  .ce_huh_media_text.col-full .media-text-2.media-left .box-media .video-player {
    margin-left: -50vw;
    left: 100%;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .ce_huh_media_text.col-full .media-text-1.media-left .box-media .image-overlay,
  .ce_huh_media_text.col-full .media-text-2.media-left .box-media .image-overlay {
    margin-left: calc((-100vw + 960px) / 2);
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  .ce_huh_media_text.col-full .media-text-1.media-left .box-media .image-overlay,
  .ce_huh_media_text.col-full .media-text-2.media-left .box-media .image-overlay {
    margin-left: calc((-100vw + 1140px) / 2);
  }
}
@media (min-width: 992px) {
  .ce_huh_media_text.col-full .media-text-4 .box-media figure,
  .ce_huh_media_text.col-full .media-text-4 .box-media .video-player {
    height: 100%;
    width: 33.33333333vw !important;
    position: relative;
  }
  .ce_huh_media_text.col-full .media-text-4 .box-media .image-overlay {
    width: 33.33333333vw !important;
    z-index: 1;
  }
  .ce_huh_media_text.col-full .media-text-4.media-right .box-media figure,
  .ce_huh_media_text.col-full .media-text-4.media-right .box-media .video-player {
    margin-right: -33.33333333vw;
    right: 0;
  }
  .ce_huh_media_text.col-full .media-text-4.media-left .box-media figure,
  .ce_huh_media_text.col-full .media-text-4.media-left .box-media .video-player {
    margin-left: -33.33333333vw;
    left: 100%;
  }
}
@media (min-width: 992px) {
  .ce_huh_media_text .media-text-2 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .ce_huh_media_text .media-text-2 .box-media {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
}
@media (min-width: 992px) {
  .ce_huh_media_text .media-text-3 .box-content-wrapper {
    position: absolute;
    height: 90%;
    top: 5%;
    z-index: 2;
    background: white;
  }
}
.ce_huh_media_text .media-text-3 .box-media {
  padding: 0;
}
@media (min-width: 992px) {
  .ce_huh_media_text .media-text-3.media-left .box-content-wrapper {
    right: 0;
  }
}
@media (min-width: 992px) {
  .ce_huh_media_text .media-text-3.media-left .box-content {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
    margin-left: 0;
    margin-right: auto;
    max-width: 480px;
  }
}
@media (min-width: 1200px) {
  .ce_huh_media_text .media-text-3.media-left .box-content {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
    margin-left: 0;
    margin-right: auto;
    max-width: 570px;
  }
}
@media (max-width: 767.98px) {
  .ce_huh_media_text .media-text-3.media-left .box-content {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .ce_huh_media_text .media-text-3.media-right .box-content {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
    margin-left: auto;
    margin-right: 0;
    max-width: 480px;
  }
}
@media (min-width: 1200px) {
  .ce_huh_media_text .media-text-3.media-right .box-content {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
    margin-left: auto;
    margin-right: 0;
    max-width: 570px;
  }
}
@media (max-width: 767.98px) {
  .ce_huh_media_text .media-text-3.media-right .box-content {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 720px;
  }
}