.ce_huh_media_grid {
  figure {
    margin: 0;
  }

  .image-overlay {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    opacity: .25;

    & ~ .text-element {
      position: absolute;
      display: block;
      width: 100%;
      top: 0;
      left: 0;
    }
  }
}
